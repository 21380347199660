<template>
  <div>
    <div id="signin" class="section">
      <div class="signin-content">
        <div class="signin-description">
          <h3>{{ $t("sign-in-1") }}</h3>
          <div>{{ $t("sign-in-2") }}</div>
        </div>

        <div class="tabs">
          <div
            v-for="(item, index) in getAvailableNetworks"
            :class="{ active: item === getNetwork }"
            @click="setNetwork(item)"
            :key="index"
          >
            {{ capitalizeFirstLetter(item) }}
          </div>
        </div>

        <div class="signin-wallets">
          <div class="item metamask" @click="auth('metamask')">
            <div class="signin-bg"></div>
            <div class="signin-title">Metamask</div>
            <div class="signin-logo">
              <img src="@/assets/img/metamask.svg" />
            </div>
          </div>

          <div class="item walletconnect" @click="auth('walletconnect')">
            <div class="signin-bg"></div>
            <div class="signin-title">Walletconnect</div>
            <div class="signin-logo">
              <img src="@/assets/img/walletconnect.svg" />
            </div>
          </div>

          <div class="item fortmatic" @click="auth('fortmatic')">
            <div class="signin-bg"></div>
            <div class="signin-title">Fortmatic</div>
            <div class="signin-logo">
              <img src="@/assets/img/fortmatic.svg" />
            </div>
          </div>
        </div>
      </div>

      <div class="signin-footer">
        <div class="copyright">© {{ new Date().getFullYear() }} QNFT</div>

        <div class="terms">
          <router-link :to="privacyPolicyLink()">Privacy Policy</router-link>
          <router-link :to="termsOfServiceLink()">Terms of Service</router-link>
        </div>
      </div>
    </div>

    <AppSprite />
  </div>
</template>

<script>
import AppSprite from "@/components/containers/AppSprite";
import api from "@/api/api";
import { mapGetters, mapMutations } from "vuex";
import connectWalletMixin from "@/mixins/connectWalletMixin";
import contract from "@/api/contract";
import setProcessingMixin from "@/mixins/setProcessingMixin";
import capitalizeFirstLetterMixin from "@/mixins/capitalizeFirstLetterMixin";
import useLinkMixin from "@/mixins/useLinkMixin";

export default {
  name: "SignIn",
  mixins: [
    useLinkMixin,
    connectWalletMixin,
    setProcessingMixin,
    capitalizeFirstLetterMixin,
  ],
  data: () => ({
    prevRoute: null,
  }),
  computed: {
    ...mapGetters({
      getAvailableNetworks: "wallet/getAvailableNetworks",
      getNetwork: "wallet/getNetwork",
      getMarketContract: "wallet/getMarketContract",
      getChanIdDec: "wallet/getChanIdDec",
      getForwarderContract: "wallet/getForwarderContract",
      getAddress: "wallet/getAddress",
    }),
  },
  methods: {
    ...mapMutations({
      setToken: "user/setToken",
      setNetwork: "wallet/setNetwork",
    }),
    async auth(connectMethod) {
      try {
        this.setLoading(true);
        let signature = null;
        const connectResult = await this.tryConnect(connectMethod);
        if (connectResult) {
          const signToken = await api.getSignToken({
            address: connectResult.address,
          });
          signature = await contract.sign(connectResult.address, signToken);

          const response = await api.auth(signature);
          this.setToken(response.accessToken);
          this.setLoading(false);
          await this.$router.push({
            path:
              this.prevRoute.path && this.prevRoute.path !== "/"
                ? this.prevRoute.path
                : this.homeLink(),
          });
        }
      } catch (e) {
        const walletError = e.message;
        const serverError = e?.response?.data.message;
        this.setError(walletError || serverError || "Unknown error occurred");
        this.setLoading(false);
      } finally {
        this.setLoading(false);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  components: { AppSprite },
};
</script>
